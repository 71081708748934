<template>
  <div class="grid">
    <div class="col-12">
      <Card>
        <template #content>
          <Toolbar class="mb-4">
            <template #start>
              <Button
                label="Voltar"
                icon="pi pi-chevron-left"
                class="mt-2 mr-2 p-button-sm p-button-secondary"
                @click="$router.back()" />
            </template>
          </Toolbar>
          <div class="p-fluid formgrid grid">
            <div class="field col-6 md:col-6">
              <label for="rubrica">Rubrica</label>
              <InputText
                id="contrato"
                v-model="exibirNomeRubrica"
                type="text"
                disabled />
            </div>
            <div class="field col-3 md:col-3">
              <label for="contrato">Nº Contrato</label>
              <InputText
                id="contrato"
                v-model="compraDivida.proposta.contrato"
                type="text"
                disabled />
            </div>
            <div class="field col-3 md:col-3">
              <label for="agencia">Agência</label>
              <InputText
                id="agencia"
                v-model="compraDivida.proposta.agencia"
                type="text"
                disabled />
            </div>
          </div>
          <div class="p-fluid formgrid grid">
            <div class="field col-6 md:col-6">
              <label for="valor">Valor Parcela</label>
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">R$</span>
                <InputText
                  id="valor"
                  v-model="compraDivida.proposta.valorParcela"
                  disabled />
              </div>
            </div>
            <div class="field col-6 md:col-6">
              <label for="status">Situação</label>
              <InputText
                id="status"
                v-model="exibirStatus"
                type="text"
                disabled />
            </div>
          </div>
          <div class="p-fluid formgrid grid">
            <div class="field col-6 md:col-6">
              <label for="prazo">Prazo Total</label>
              <InputText
                id="prazo"
                v-model="compraDivida.proposta.prazoTotal"
                type="text"
                disabled />
            </div>
            <div class="field col-6 md:col-6">
              <label for="anoReferencia">Ano</label>
              <InputText
                id="anoReferencia"
                v-model="compraDivida.proposta.anoReferencia"
                type="text"
                disabled />
            </div>
          </div>
          <div class="p-fluid formgrid grid">
            <div class="field col-6 md:col-6">
              <label for="mesReferencia">Mês</label>
              <InputText
                id="mesReferencia"
                v-model="compraDivida.proposta.mesReferencia"
                type="text"
                disabled />
            </div>
          </div>
          <div class="p-fluid formgrid grid">
            <div class="field col-12 md:col-12">
              <label for="observacao">Observação</label>
              <Textarea
                id="observacao"
                v-model="compraDivida.proposta.obs"
                :autoResize="true"
                disabled />
            </div>
          </div>
          <div class="p-fluid formgrid grid">
            <div class="field col-12 md:col-12">
              <label for="consignacao">Consignações Negociadas</label>
              <Textarea
                id="consignacao"
                v-model="consignacoes"
                :autoResize="true"
                disabled />
            </div>
          </div>
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
import CompraDividaService from '@/service/CompraDividaService'
import CompraDivida from '@/domain/CompraDivida.js'

export default {
  data() {
    return {
      compraDivida: new CompraDivida(),
      consignacoes: [],
      id: this.$route.params.idcompradivida,
    }
  },

  computed: {
    exibirNomeRubrica() {
      return typeof this.compraDivida.proposta.rubrica != 'undefined'
        ? `${this.compraDivida.proposta.rubrica.rubrica} | ${this.compraDivida.proposta.rubrica.nome}`
        : ''
    },
    exibirStatus() {
      return typeof this.compraDivida.proposta.statusProposta != 'undefined'
        ? `${this.compraDivida.proposta.statusProposta.nome}`
        : ''
    },
  },

  created() {
    this.service = new CompraDividaService(this.$http)
  },

  mounted() {
    this.carregarCompraDivida()
  },

  methods: {
    carregarCompraDivida() {
      this.service.getCompraDivida(this.id).then(
        (res) => {
          this.compraDivida = res
          this.formatarExibicaoConsignacoes()
        },
        (err) => {
          this.exibeToast('error', err.response.data.message)
        },
      )
    },

    formatarExibicaoConsignacoes() {
      this.consignacoes = this.compraDivida.consignacoes
        ? this.compraDivida.consignacoes
            .map((consignacao) => {
              return `Contrato: ${consignacao.numeroContrato}. Rubrica: ${
                typeof consignacao.rubrica != 'undefined'
                  ? consignacao.rubrica.rubrica
                  : ''
              } | ${
                typeof consignacao.rubrica != 'undefined'
                  ? consignacao.rubrica.nome
                  : ''
              }`
            })
            .join('\n')
        : []
    },

    exibeToast(severity, msg) {
      this.$toast.add({
        severity: severity,
        summary: msg,
        life: 10000,
      })
    },
  },
}
</script>

<style></style>
