<template>
  <CompraDividaDetalhe />
</template>

<script>
import CompraDividaDetalhe from '@/components/shared/compraDivida/CompraDividaDetalhe.vue'

export default {
  components: {
    CompraDividaDetalhe,
  },
}
</script>
